.tableBorder {
  border: none !important;
  padding: 1.5% 1% 1.5% 0.1% !important;
  word-wrap: normal;
}

.printButton:hover{
  border:2px solid !important;
}

.margin-top-lastPara{
  margin-top: 40vh !important;
}

.blueCard{
  height:200%;
}
.printSize {
  width: "100%";
  height: "100%";
  padding: "2%";
}

.redColor{
  color:red !important
}

.greenColor{
  color:green !important
}

.SkeletonPadding{
  padding: 5%;
}

.paymentCard {
  height: auto !important;
  border-radius: 12px !important;
  padding: 20px !important;
  background-color: #ffffff !important;
  margin-bottom: 30px !important;
}

.text {
  padding: 2%;
}

*:focus {
  outline: none !important;
}
.rowPadding{
  padding: none !important;
}

.heightSkeleton{
  height:400%
}

.lastPara{
  margin-top:35% !important;
  padding-right:1vw !important ;
}
.floatLeft{
  float: left;
}
@media print {
  body {
    padding-top: 5%;
  }
}

@media only screen and (max-width: 960px) {
  .blueCard{
    height:100% !important;
  }
  .lastPara{
    padding-right:0 !important ;
  }
}

.paddingList{
  padding: 0% !important;
}
