.profile-photo {
  border-radius: 50%;
  width: 15px;
  border: 3px solid #ffffff;
}
.main-content {
  display: flex;
  flex-direction: column;
  margin-bottom: 100px; 
  margin-top:3vw;
}

.app-logo {
  max-width: 9.375rem !important;
}

@media only screen and (min-width: 768px) {
  .main-content {
    padding: 0px !important;
    margin: 0px 30px 0px 140px;
  }
}

/* Footer css */

.footer-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.privacy-terms {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.backtotop {
  margin-top: 3% !important;
}

.backtotop:hover {
  color: #5bc0eb !important;
}

.profile-menu-item {
  display: flex !important;
  flex-direction: column !important;
  align-items: flex-start !important;
  width: 100% !important;
  background-color: #fff;
}

.profile-menu-item:hover {
  display: flex !important;
  flex-direction: column !important;
  align-items: flex-start !important;
  background-color: #9e9e9e !important;
}

.cursor-pointer {
  cursor: pointer;
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .privacy-terms {
    display: flex;
    justify-content: center;
  }
  .links {
    display: flex;
    justify-content: center;
  }
  /* .backtotop {
    margin-right: 21vh !important;
  } */
  @media only screen and (max-width: 500px) {
    .app-logo {
      width: 120% !important;
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .footer-component {
    display: none;
  }
  .margin-top-30 {
    margin-bottom: 100px;
  }
  .links {
    display: flex;
    justify-content: center;
  }
  /* .backtotop {
    margin-right: 35vh;
  } */
}

@media screen and (max-width: 764px) {
  .footer-component {
    display: none;
  }
}

/* @media only screen and (min-width: 438px) and (max-width: 911px) {
  .backtotop {
    margin-right: 67vh !important;
  }
  @media only screen and (height: 1024px) {
    .backtotop {
      margin-right: 21vh !important;
    }
  }
} */

@media only screen and (min-width: 500px) and (max-width: 1093px) {
  .privacy-terms {
    display: flex;
    justify-content: center;
  }
  .links {
    display: flex;
    justify-content: center;
  }
  
}

/* Fotter css ends */
