.card-style{
    background-color: #004987 !important;
    padding-bottom: 50vh !important;
    bottom: 0% !important;
    justify-content: center !important;
    overflow: auto !important;
  }
 .icons{
   font-size: 6rem !important;
 }
 .righ-text{
   /* color: "#FFFFFF" !important; */
   font-size: 20rem !important;
 }
 .div
 {
  z-index: 0 !important;
  position: absolute !important;
  top: 0% !important;
  bottom: -12.5% !important;
  right: -1% !important;
  left: -1% !important;
  overflow: hidden !important;
 }
 .link {
  text-align: left;
  font-size: 1rem !important ; 
	background: none;
	margin: 0;
	padding: 0;
  border: none;
  color:#FFFFFF !important;
  cursor: pointer;
  text-transform: lowercase !important;
  text-decoration: underline !important;
}
.link:hover{
  color:#FFFFFF !important;
}
.mailLink{
  color:#FFFFFF !important;
}
.mailLink:hover{
  color:#FFFFFF !important;
  cursor: default; 
  text-decoration: none;
}
