.link:hover {
  text-decoration: none;
  text-transform: lowercase;
}

.cardFallback{
  margin: 4.5rem 0  !important;

}

.wrapSelect {
  max-width: 120px !important;
}
.labelfont {
  font-size: 1.4rem !important;
}
.leftDropdown {
  margin-left: none !important;
}
.rightDropdown {
  margin-left: none !important;
}  
.rightDropdownInner {
  margin-left: 1.2vw !important;
}

.leftDropdownInner{
  padding-right: 2vw !important;
}

@media only screen and (max-width: 959px) {
  .leftDropdown{
    margin-left: -5vw !important;
  }
}

@media only screen and (max-width: 600px){
  .leftDropdown{
    margin-left: -14vw !important;
  }
  .wrapSelect {
    max-width: 90px !important;
  }
  .labelfont {
    font-size: 1.1rem !important;
  }
}

@media only screen and (max-width: 300px) {
  .leftDropdown {
    margin-left: -21vw !important;
  } 
  .rightDropdown{
    margin-left: -12vw !important;
  } 
} 
.wrapWord {
  word-break: break-word !important;
}

