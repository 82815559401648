@import url(https://fonts.googleapis.com/css?family=Lato);
@import url(https://fonts.googleapis.com/css?family=Montserrat);
@import url(https://fonts.googleapis.com/css?family=Noto+Sans);
@import url(https://fonts.googleapis.com/css?family=Pangolin);
.account-details-align {
  margin-left: 28px;
}

.account-type-align {
  margin-left: 38px;
}

.show-meters {
  cursor: pointer;
}

.account-information:hover {
  background-color: lightgray !important;
}

.imageWidth{
  margin-left: 35% !important;
}

.wordBreakMargin{
  margin-top: 4% !important;
  word-wrap:break-word;
}

.tableAlignment {
vertical-align: baseline;
}

.profileMargin{
  margin-top:6% !important;
}

.wordBreakProfile{
  word-wrap:break-word;
}
.chartStyle {
  height: 100%;
}

*:focus {
  outline: none !important;
}

.floatLeft {
  float: left;
  margin-bottom: 5px;
}
.floatLeft:hover {
  background-color: lightgray !important;
}

.usageFont {
  font-size: 9px;
}

.UsageWeekFont {
  font-size: 12px;
}

.graphDiv {
  padding: 10px;
  margin: -10px;
  margin-right: -40px;
}
.dividerVertical {
  display: flex;
  flex-direction: column;
  height: auto;
  padding: 0.5px;
  border: none;
  margin: 0px;
  margin-right: 27px;
  align-items: center;
  justify-content: center;
}

.disablecursor {
  cursor: no-drop !important;
  background-color: #fa8072 !important;
}

.lastpaid {
  display: flex !important;
  flex-wrap: wrap !important;
  justify-content: center;
  margin-bottom: 10px;
  color: #839192;
}

.usagetitle {
  margin-top: -20px;
}
.buttonText {
  text-transform: unset !important;
}
.smDivclass {
  text-align: right;
  margin-top: 10px;
}

.smDivLeft {
  text-align: left;
}
.lineChart {
  height: 300px !important;
}
.smallScreenstyles {
  text-align: center;
}
.bigScreenstyles {
  text-align: left;
}
.buttonDisable {
  padding: 1vh !important;
}

@media all and (max-width: 768px) {
  .dividerVertical {
    width: 100% !important;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .floatLeft {
    margin-left: 10% !important;
  }
 
}

.titleTransform {
  text-transform: uppercase !important;
}







.link:hover {
  text-decoration: none;
  text-transform: lowercase;
}

.cardFallback{
  margin: 4.5rem 0  !important;

}

.wrapSelect {
  max-width: 120px !important;
}
.labelfont {
  font-size: 1.4rem !important;
}
.leftDropdown {
  margin-left: none !important;
}
.rightDropdown {
  margin-left: none !important;
}  
.rightDropdownInner {
  margin-left: 1.2vw !important;
}

.leftDropdownInner{
  padding-right: 2vw !important;
}

@media only screen and (max-width: 959px) {
  .leftDropdown{
    margin-left: -5vw !important;
  }
}

@media only screen and (max-width: 600px){
  .leftDropdown{
    margin-left: -14vw !important;
  }
  .wrapSelect {
    max-width: 90px !important;
  }
  .labelfont {
    font-size: 1.1rem !important;
  }
}

@media only screen and (max-width: 300px) {
  .leftDropdown {
    margin-left: -21vw !important;
  } 
  .rightDropdown{
    margin-left: -12vw !important;
  } 
} 
.wrapWord {
  word-break: break-word !important;
}


.back-banner {
  width: 100%;
  min-height: 40vh;
}

.bottom-bar {
  width: 100%;
  min-height: 60vh;
}

.stack-top {
  z-index: 2;
  position: absolute;
  top: 10%;
  bottom: 10%;
  right: 1%;
  left: 1%;
}

.linkText {
  font-size: 15px !important;
  color: #7ccdef !important;
}

.smallLabel {
  font-size: 14px !important;
  color: #b4c9d5 !important;
}
.card {
  min-height: 17.6rem !important;
  border-radius: 12px !important;
  padding: 8px !important;
  background-color: #ffffff !important;
}

.margin-0 {
  margin: 0px;
}

.margin-10 {
  margin: 10px;
}

.margin-20 {
  margin: 20px;
}

.margin-30 {
  margin: 30px;
}

.margin-top-10 {
  margin-top: 10px;
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-top-30 {
  margin-top: 30px;
}

.margin-top-40 {
  margin-top: 40px;
}

.margin-top-50 {
  margin-top: 50px;
}

.margin-top-55 {
  margin-top: 55px;
}

.margin-top-60 {
  margin-top: 60px;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.margin-bottom-30 {
  margin-bottom: 30px;
}

.margin-bottom-40 {
  margin-bottom: 40px;
}

.margin-bottom-50 {
  margin-bottom: 50px;
}

.margin-right-10 {
  margin-right: 10px;
}

.margin-right-20 {
  margin-right: 20px;
}

.margin-right-30 {
  margin-right: 30px;
}

.margin-right-40 {
  margin-right: 40px;
}

.margin-right-50 {
  margin-right: 50px;
}

.padding-0 {
  padding: 0px;
}

.padding-10 {
  padding: 10px;
}

.padding-20 {
  padding: 20px;
}

.padding-30 {
  padding: 30px;
}

.padding-top-10 {
  padding-top: 10px;
}

.padding-top-20 {
  padding-top: 20px;
}

.padding-top-30 {
  padding-top: 30px;
}

.padding-top-40 {
  padding-top: 40px;
}

.padding-top-50 {
  padding-top: 50px;
}

.padding-bottom-10 {
  padding-bottom: 10px;
}

.padding-bottom-20 {
  padding-bottom: 20px;
}

.padding-bottom-30 {
  padding-bottom: 30px;
}

.padding-bottom-40 {
  padding-bottom: 40px;
}

.padding-bottom-50 {
  padding-bottom: 50px;
}

.padding-right-10 {
  padding-right: 10px;
}

.padding-right-20 {
  padding-right: 20px;
}

.padding-right-30 {
  padding-right: 30px;
}

.padding-right-40 {
  padding-right: 40px;
}

.padding-right-50 {
  padding-right: 50px;
}

.float-left {
  float: left;
}

.clearboth {
  clear: both;
}

.width100 {
  width: 100%;
}
.selfhelp-links {
  text-align: left;
}
.sidemenuContent {
  padding: 0px;
  margin-left: -20px;
}
.header__2lLFY {
  margin-top: 50px !important;
}
@keyframes blinker {
  50% {
      opacity: 0;
  }
}

@media screen and (min-width: 0px) and (max-width: 767px) { 
  .rocketchat-widget{
    margin-bottom: 75px;
  }
  .rocketchat-container{
    height: 90% !important;
    margin-top: 20% !important;
  }
 

}

/* @media only screen and (max-width: 540px) {
  .rocketchat-widget{
    margin-bottom: 75px;
  }
  .rocketchat-container{
    height: 90% !important;
    margin-top: 20% !important;
  }
}
@media only screen and (min-height: 720px) {
  .rocketchat-widget{
    margin-bottom: 50px;
  }
  .rocketchat-container{
    height: 90% !important;
    margin-top: 15% !important;
  }
}

@media only screen and (max-width: 414px) {
  .rocketchat-widget{
    margin-bottom: 75px;
  }
  .rocketchat-container{
    height: 90% !important;
    margin-top: 20% !important;
  }
}
@media only screen and (min-height: 736px) {
  .rocketchat-widget{
    margin-bottom: 90px;
  }
  .rocketchat-container{
    height: 92% !important;
    margin-top: 20% !important;
  }
}

@media only screen and (max-width: 540px) {
  .rocketchat-widget{
    margin-bottom: 75px;
  }
  .rocketchat-container{
    height: 90% !important;
    margin-top: 20% !important;
  }
}
@media only screen and (min-height: 720px) {
  .rocketchat-widget{
    margin-bottom: 80px;
  }
  .rocketchat-container{
    height: 92% !important;
    margin-top: 20% !important;
  }
}

@media only screen and (max-width: 280px) {
  .rocketchat-widget{
    margin-bottom: 75px;
  }
  .rocketchat-container{
    height: 90% !important;
    margin-top: 20% !important;
  }
}
@media only screen and (min-height: 653px) {
  .rocketchat-widget{
    margin-bottom: 90px;
  }
  .rocketchat-container{
    height: 92% !important;
    margin-top: 30% !important;
  }
} */
@media all and (max-width: 1390px) {
  .MuiPopover-paper {   
    width: auto !important;
  }
}
.pagination {
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;
}

.active .activeLinks {
  margin-left: -5vh;
  color: inherit;
}

.active {
  list-style-type: none;
}

.faqscls {
  display: flex;
  justify-content: center;
}

.faqcard {
  min-height: 17.6rem !important;
  border-radius: 12px !important;
  padding: 20px !important;
  background-color: #ffffff !important;
  margin-bottom: 30px !important;  
}
.innerExpansion{
  display: inline !important;
}
.marginRight{
  margin-right: -10px;
}

.fontSizeFAQ{
  font-size: 16px !important;
}
.tableBorder {
  border: none !important;
  padding: 1.5% 1% 1.5% 0.1% !important;
  word-wrap: normal;
}

.printButton:hover{
  border:2px solid !important;
}

.margin-top-lastPara{
  margin-top: 40vh !important;
}

.blueCard{
  height:200%;
}
.printSize {
  width: "100%";
  height: "100%";
  padding: "2%";
}

.redColor{
  color:red !important
}

.greenColor{
  color:green !important
}

.SkeletonPadding{
  padding: 5%;
}

.paymentCard {
  height: auto !important;
  border-radius: 12px !important;
  padding: 20px !important;
  background-color: #ffffff !important;
  margin-bottom: 30px !important;
}

.text {
  padding: 2%;
}

*:focus {
  outline: none !important;
}
.rowPadding{
  padding: none !important;
}

.heightSkeleton{
  height:400%
}

.lastPara{
  margin-top:35% !important;
  padding-right:1vw !important ;
}
.floatLeft{
  float: left;
}
@media print {
  body {
    padding-top: 5%;
  }
}

@media only screen and (max-width: 960px) {
  .blueCard{
    height:100% !important;
  }
  .lastPara{
    padding-right:0 !important ;
  }
}

.paddingList{
  padding: 0% !important;
}

.icon {
  display: flex;
  justify-content: center;
}

.linkText {
  display: flex;
  justify-content: center;
}
.linkTextDisable {
  display: flex;
  font-size: 15px !important;
  justify-content: center;
}

/* .dividerHorizontal {
  padding: 0.6px !important;
  width: 100%
} */
.amountDueTitle {
  font-size: 15px;
  text-align: center;
  line-height: 25px;
}
.dividerHorizontal {
  padding: 0.6px !important;
  width: 100%;
}

.MuiListItemText-root {
  flex: 0 1 auto !important;
}

.MuiListItem-root {
  justify-content: center !important;
}

.dividerVertical {
  display: flex;
  flex-direction: column;
  height: auto;
  padding: 0.5px;
  border: none;
  margin: 0px;
  background: rgba(0, 0, 0, 0.12);
  margin-right: 27px;
  align-items: center;
  justify-content: center;
}
h4 {
  color: #f14f3f;
  font-size: 20px;
  text-align: center;
}
.duemoney {
  color: #064e74;
  font-size: 2rem !important;
  font-weight: 600 !important;
  text-align: center;
}
.duetext {
  color: #064e74;
  font-size: 15px;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
}
.paynow {
  height: 39px;
  width: 155px;
  border-radius: 19.5px;
  background-color: #f14f3f;
}
.noDataFound{
  margin-top: 20px !important;
}
@media all and (max-width: 768px) {
  .dividerVertical {
    width: 100% !important;
    background: rgba(0, 0, 0, 0.12) !important;
  }
}

@media all and (max-width: 768px) {
  .MuiGrid-grid-sm-4 {
    flex-grow: 0 !important;
    max-width: 100% !important;
    flex-basis: 100% !important;
  }
}

@media all and (max-width: 768px) {
  .MuiGrid-grid-sm-8 {
    flex-grow: 0 !important;
    max-width: 100% !important;
    flex-basis: 100% !important;
  }
}

@media all and (max-width: 768px) {
  .MuiGrid-grid-sm-7 {
    flex-grow: 0 !important;
    max-width: 100% !important;
    flex-basis: 100% !important;
  }
}

@media all and (max-width: 768px) {
  .MuiGrid-grid-xs-4 {
    flex-grow: 0;
    max-width: 100% !important;
    flex-basis: 100% !important;
  }
}

.MuiListItem-root {
  padding-bottom: 0px !important;
}

.listStyling {
  width: 100%;
  padding-top: 0px;
  padding-bottom: 0px;
}

.cardContent {
  color: white;
  text-align: center;
}
.usageCard {
  width: 100%;
}

.css-yk16xz-control, .css-g1d714-ValueContainer {
  border: none !important;
  width: 130px !important;
  /* color: #f14f3f !important; */
}
.css-1pahdxg-control {
  border: none !important;
  width: 130px !important;
}
.css-1okebmr-indicatorSeparator{
  background-color: white !important;
}
.css-6q0nyr-Svg,  .css-2613qy-menu {
  fill: #f14f3f !important;
}

.css-1uccc91-singleValue {
  color: #f14f3f !important;
  padding-left: 15%;
  font-size: 15px;
  font-weight: 515;
  letter-spacing: 0;
}


.displayWrap {
  display: flex;
  flex-wrap: wrap;
  position: absolute;
}

.headerMargin {
  margin-top: 8% !important;
}

.graph{
    height: 100px !important;
}

.css-1okebmr-indicatorSeparator {
  display: none;
  padding-bottom: 10%;
}

.css-6q0nyr-Svg {
  fill: #f14f3f !important;
}

.css-2613qy-menu {
  color: #f14f3f !important;
}
/* 
.MuiPaper-elevation1 {
  box-shadow: none !important;
}

.MuiExpansionPanel-root:before {
  background-color: white !important;
}

.MuiButton-root {
  text-transform: none !important;
} */

.btnclass:hover {
  background-color: white !important;
}

.btnclass,
.costButton {
  outline: none !important;
}

.costButton:focus {
  background-color: rgba(91, 192, 235, 0.2) !important;
  border-radius: 4px;
}

/* .MuiSelect-selectMenu:focus {
  background-color: rgba(91, 192, 235, 0.2) !important;
} */

.css-zk88xw-singleValue {
  color: #f14f3f !important;
}
.float-right {
  float: right;
}

.lightColor {
  color: #cccaca;
}

.space {
  margin-right: 30px;
}

/* @media only screen and (max-width: 1030px) {
  .headerMargin {
    margin-top: 10% !important;
  }
}

@media only screen and (max-width: 960px) {
  .headerMargin {
    margin-top: 12% !important;
  }
}

@media only screen and (max-width: 860px) {
  .headerMargin {
    margin-top: 15% !important;
  }
}


@media only screen and (max-width: 600px) {
  .MuiGrid-container {
    flex-wrap: nowrap !important;
  }
  .space {
    margin-right: 3px;
  }
  .headerMargin {
    margin-top: 45% !important;
  }

  .break {
    flex-wrap: wrap !important;
  }
}

@media only screen and (max-width: 470px) {
  .space {
    margin-right: 10px;
  }

} */ 

.profile-photo {
  border-radius: 50%;
  width: 15px;
  border: 3px solid #ffffff;
}
.main-content {
  display: flex;
  flex-direction: column;
  margin-bottom: 100px; 
  margin-top:3vw;
}

.app-logo {
  max-width: 9.375rem !important;
}

@media only screen and (min-width: 768px) {
  .main-content {
    padding: 0px !important;
    margin: 0px 30px 0px 140px;
  }
}

/* Footer css */

.footer-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.privacy-terms {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.backtotop {
  margin-top: 3% !important;
}

.backtotop:hover {
  color: #5bc0eb !important;
}

.profile-menu-item {
  display: flex !important;
  flex-direction: column !important;
  align-items: flex-start !important;
  width: 100% !important;
  background-color: #fff;
}

.profile-menu-item:hover {
  display: flex !important;
  flex-direction: column !important;
  align-items: flex-start !important;
  background-color: #9e9e9e !important;
}

.cursor-pointer {
  cursor: pointer;
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .privacy-terms {
    display: flex;
    justify-content: center;
  }
  .links {
    display: flex;
    justify-content: center;
  }
  /* .backtotop {
    margin-right: 21vh !important;
  } */
  @media only screen and (max-width: 500px) {
    .app-logo {
      width: 120% !important;
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .footer-component {
    display: none;
  }
  .margin-top-30 {
    margin-bottom: 100px;
  }
  .links {
    display: flex;
    justify-content: center;
  }
  /* .backtotop {
    margin-right: 35vh;
  } */
}

@media screen and (max-width: 764px) {
  .footer-component {
    display: none;
  }
}

/* @media only screen and (min-width: 438px) and (max-width: 911px) {
  .backtotop {
    margin-right: 67vh !important;
  }
  @media only screen and (height: 1024px) {
    .backtotop {
      margin-right: 21vh !important;
    }
  }
} */

@media only screen and (min-width: 500px) and (max-width: 1093px) {
  .privacy-terms {
    display: flex;
    justify-content: center;
  }
  .links {
    display: flex;
    justify-content: center;
  }
  
}

/* Fotter css ends */

.footer-cls {
  display: flex;
  flex: auto;
  flex-direction: row;
  align-items: baseline;
}

.mobile-copyright {
  margin: 0px 54px 12px;
  margin-top: -9px;
}
.closeIcon {
  margin-left: 200px !important;
}

@media only screen and (min-width: 411px) and (max-width: 731px) {
  .footer-cls {
    margin: 0px 68px 12px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 568px) {
  .footer-cls {
    margin: 0px 27px 12px;
  }
}
@media only screen and (min-width: 375px) and (max-width: 667px) {
  .footer-cls {
    margin: 0px 51px 12px;
  }
}

@media only screen and (min-width: 414px) and (max-width: 736px) and (orientation:landscape){
  .footer-cls {
    margin: 0px 56vh 12px;
  }
}

@media only screen and (width: 640px) and (height: 360px) {
  .closeIcon {
    margin-right: -280px !important;
  }
}
@media only screen and (min-width: 360px) and (max-width: 640px) and (orientation:landscape){
  .footer-cls {
    margin: 0px 50vh 12px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 667px) and (orientation:landscape){
  .footer-cls {
    margin: 0px 47vh 12px;
  }
}

.MuiDialog-paper {
  margin: 0px !important;
}
.card-style{
    background-color: #004987 !important;
    padding-bottom: 50vh !important;
    bottom: 0% !important;
    justify-content: center !important;
    overflow: auto !important;
  }
 .icons{
   font-size: 6rem !important;
 }
 .righ-text{
   /* color: "#FFFFFF" !important; */
   font-size: 20rem !important;
 }
 .div
 {
  z-index: 0 !important;
  position: absolute !important;
  top: 0% !important;
  bottom: -12.5% !important;
  right: -1% !important;
  left: -1% !important;
  overflow: hidden !important;
 }
 .link {
  text-align: left;
  font-size: 1rem !important ; 
	background: none;
	margin: 0;
	padding: 0;
  border: none;
  color:#FFFFFF !important;
  cursor: pointer;
  text-transform: lowercase !important;
  text-decoration: underline !important;
}
.link:hover{
  color:#FFFFFF !important;
}
.mailLink{
  color:#FFFFFF !important;
}
.mailLink:hover{
  color:#FFFFFF !important;
  cursor: default; 
  text-decoration: none;
}

