.css-yk16xz-control, .css-g1d714-ValueContainer {
  border: none !important;
  width: 130px !important;
  /* color: #f14f3f !important; */
}
.css-1pahdxg-control {
  border: none !important;
  width: 130px !important;
}
.css-1okebmr-indicatorSeparator{
  background-color: white !important;
}
.css-6q0nyr-Svg,  .css-2613qy-menu {
  fill: #f14f3f !important;
}

.css-1uccc91-singleValue {
  color: #f14f3f !important;
  padding-left: 15%;
  font-size: 15px;
  font-weight: 515;
  letter-spacing: 0;
}


.displayWrap {
  display: flex;
  flex-wrap: wrap;
  position: absolute;
}

.headerMargin {
  margin-top: 8% !important;
}

.graph{
    height: 100px !important;
}

.css-1okebmr-indicatorSeparator {
  display: none;
  padding-bottom: 10%;
}

.css-6q0nyr-Svg {
  fill: #f14f3f !important;
}

.css-2613qy-menu {
  color: #f14f3f !important;
}
/* 
.MuiPaper-elevation1 {
  box-shadow: none !important;
}

.MuiExpansionPanel-root:before {
  background-color: white !important;
}

.MuiButton-root {
  text-transform: none !important;
} */

.btnclass:hover {
  background-color: white !important;
}

.btnclass,
.costButton {
  outline: none !important;
}

.costButton:focus {
  background-color: rgba(91, 192, 235, 0.2) !important;
  border-radius: 4px;
}

/* .MuiSelect-selectMenu:focus {
  background-color: rgba(91, 192, 235, 0.2) !important;
} */

.css-zk88xw-singleValue {
  color: #f14f3f !important;
}
.float-right {
  float: right;
}

.lightColor {
  color: #cccaca;
}

.space {
  margin-right: 30px;
}

/* @media only screen and (max-width: 1030px) {
  .headerMargin {
    margin-top: 10% !important;
  }
}

@media only screen and (max-width: 960px) {
  .headerMargin {
    margin-top: 12% !important;
  }
}

@media only screen and (max-width: 860px) {
  .headerMargin {
    margin-top: 15% !important;
  }
}


@media only screen and (max-width: 600px) {
  .MuiGrid-container {
    flex-wrap: nowrap !important;
  }
  .space {
    margin-right: 3px;
  }
  .headerMargin {
    margin-top: 45% !important;
  }

  .break {
    flex-wrap: wrap !important;
  }
}

@media only screen and (max-width: 470px) {
  .space {
    margin-right: 10px;
  }

} */ 
