.back-banner {
  width: 100%;
  min-height: 40vh;
}

.bottom-bar {
  width: 100%;
  min-height: 60vh;
}

.stack-top {
  z-index: 2;
  position: absolute;
  top: 10%;
  bottom: 10%;
  right: 1%;
  left: 1%;
}

.linkText {
  font-size: 15px !important;
  color: #7ccdef !important;
}

.smallLabel {
  font-size: 14px !important;
  color: #b4c9d5 !important;
}
.card {
  min-height: 17.6rem !important;
  border-radius: 12px !important;
  padding: 8px !important;
  background-color: #ffffff !important;
}

.margin-0 {
  margin: 0px;
}

.margin-10 {
  margin: 10px;
}

.margin-20 {
  margin: 20px;
}

.margin-30 {
  margin: 30px;
}

.margin-top-10 {
  margin-top: 10px;
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-top-30 {
  margin-top: 30px;
}

.margin-top-40 {
  margin-top: 40px;
}

.margin-top-50 {
  margin-top: 50px;
}

.margin-top-55 {
  margin-top: 55px;
}

.margin-top-60 {
  margin-top: 60px;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.margin-bottom-30 {
  margin-bottom: 30px;
}

.margin-bottom-40 {
  margin-bottom: 40px;
}

.margin-bottom-50 {
  margin-bottom: 50px;
}

.margin-right-10 {
  margin-right: 10px;
}

.margin-right-20 {
  margin-right: 20px;
}

.margin-right-30 {
  margin-right: 30px;
}

.margin-right-40 {
  margin-right: 40px;
}

.margin-right-50 {
  margin-right: 50px;
}

.padding-0 {
  padding: 0px;
}

.padding-10 {
  padding: 10px;
}

.padding-20 {
  padding: 20px;
}

.padding-30 {
  padding: 30px;
}

.padding-top-10 {
  padding-top: 10px;
}

.padding-top-20 {
  padding-top: 20px;
}

.padding-top-30 {
  padding-top: 30px;
}

.padding-top-40 {
  padding-top: 40px;
}

.padding-top-50 {
  padding-top: 50px;
}

.padding-bottom-10 {
  padding-bottom: 10px;
}

.padding-bottom-20 {
  padding-bottom: 20px;
}

.padding-bottom-30 {
  padding-bottom: 30px;
}

.padding-bottom-40 {
  padding-bottom: 40px;
}

.padding-bottom-50 {
  padding-bottom: 50px;
}

.padding-right-10 {
  padding-right: 10px;
}

.padding-right-20 {
  padding-right: 20px;
}

.padding-right-30 {
  padding-right: 30px;
}

.padding-right-40 {
  padding-right: 40px;
}

.padding-right-50 {
  padding-right: 50px;
}

.float-left {
  float: left;
}

.clearboth {
  clear: both;
}

.width100 {
  width: 100%;
}
.selfhelp-links {
  text-align: left;
}
.sidemenuContent {
  padding: 0px;
  margin-left: -20px;
}
.header__2lLFY {
  margin-top: 50px !important;
}
@keyframes blinker {
  50% {
      opacity: 0;
  }
}

@media screen and (min-width: 0px) and (max-width: 767px) { 
  .rocketchat-widget{
    margin-bottom: 75px;
  }
  .rocketchat-container{
    height: 90% !important;
    margin-top: 20% !important;
  }
 

}

/* @media only screen and (max-width: 540px) {
  .rocketchat-widget{
    margin-bottom: 75px;
  }
  .rocketchat-container{
    height: 90% !important;
    margin-top: 20% !important;
  }
}
@media only screen and (min-height: 720px) {
  .rocketchat-widget{
    margin-bottom: 50px;
  }
  .rocketchat-container{
    height: 90% !important;
    margin-top: 15% !important;
  }
}

@media only screen and (max-width: 414px) {
  .rocketchat-widget{
    margin-bottom: 75px;
  }
  .rocketchat-container{
    height: 90% !important;
    margin-top: 20% !important;
  }
}
@media only screen and (min-height: 736px) {
  .rocketchat-widget{
    margin-bottom: 90px;
  }
  .rocketchat-container{
    height: 92% !important;
    margin-top: 20% !important;
  }
}

@media only screen and (max-width: 540px) {
  .rocketchat-widget{
    margin-bottom: 75px;
  }
  .rocketchat-container{
    height: 90% !important;
    margin-top: 20% !important;
  }
}
@media only screen and (min-height: 720px) {
  .rocketchat-widget{
    margin-bottom: 80px;
  }
  .rocketchat-container{
    height: 92% !important;
    margin-top: 20% !important;
  }
}

@media only screen and (max-width: 280px) {
  .rocketchat-widget{
    margin-bottom: 75px;
  }
  .rocketchat-container{
    height: 90% !important;
    margin-top: 20% !important;
  }
}
@media only screen and (min-height: 653px) {
  .rocketchat-widget{
    margin-bottom: 90px;
  }
  .rocketchat-container{
    height: 92% !important;
    margin-top: 30% !important;
  }
} */