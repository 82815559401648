.pagination {
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;
}

.active .activeLinks {
  margin-left: -5vh;
  color: inherit;
}

.active {
  list-style-type: none;
}

.faqscls {
  display: flex;
  justify-content: center;
}

.faqcard {
  min-height: 17.6rem !important;
  border-radius: 12px !important;
  padding: 20px !important;
  background-color: #ffffff !important;
  margin-bottom: 30px !important;  
}
.innerExpansion{
  display: inline !important;
}
.marginRight{
  margin-right: -10px;
}

.fontSizeFAQ{
  font-size: 16px !important;
}