.account-details-align {
  margin-left: 28px;
}

.account-type-align {
  margin-left: 38px;
}

.show-meters {
  cursor: pointer;
}

.account-information:hover {
  background-color: lightgray !important;
}

.imageWidth{
  margin-left: 35% !important;
}

.wordBreakMargin{
  margin-top: 4% !important;
  word-wrap:break-word;
}

.tableAlignment {
vertical-align: baseline;
}

.profileMargin{
  margin-top:6% !important;
}

.wordBreakProfile{
  word-wrap:break-word;
}