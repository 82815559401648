.footer-cls {
  display: flex;
  flex: auto;
  flex-direction: row;
  align-items: baseline;
}

.mobile-copyright {
  margin: 0px 54px 12px;
  margin-top: -9px;
}
.closeIcon {
  margin-left: 200px !important;
}

@media only screen and (min-width: 411px) and (max-width: 731px) {
  .footer-cls {
    margin: 0px 68px 12px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 568px) {
  .footer-cls {
    margin: 0px 27px 12px;
  }
}
@media only screen and (min-width: 375px) and (max-width: 667px) {
  .footer-cls {
    margin: 0px 51px 12px;
  }
}

@media only screen and (min-width: 414px) and (max-width: 736px) and (orientation:landscape){
  .footer-cls {
    margin: 0px 56vh 12px;
  }
}

@media only screen and (width: 640px) and (height: 360px) {
  .closeIcon {
    margin-right: -280px !important;
  }
}
@media only screen and (min-width: 360px) and (max-width: 640px) and (orientation:landscape){
  .footer-cls {
    margin: 0px 50vh 12px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 667px) and (orientation:landscape){
  .footer-cls {
    margin: 0px 47vh 12px;
  }
}

.MuiDialog-paper {
  margin: 0px !important;
}