.chartStyle {
  height: 100%;
}

*:focus {
  outline: none !important;
}

.floatLeft {
  float: left;
  margin-bottom: 5px;
}
.floatLeft:hover {
  background-color: lightgray !important;
}

.usageFont {
  font-size: 9px;
}

.UsageWeekFont {
  font-size: 12px;
}

.graphDiv {
  padding: 10px;
  margin: -10px;
  margin-right: -40px;
}
.dividerVertical {
  display: flex;
  flex-direction: column;
  height: auto;
  padding: 0.5px;
  border: none;
  margin: 0px;
  margin-right: 27px;
  align-items: center;
  justify-content: center;
}

.disablecursor {
  cursor: no-drop !important;
  background-color: #fa8072 !important;
}

.lastpaid {
  display: flex !important;
  flex-wrap: wrap !important;
  justify-content: center;
  margin-bottom: 10px;
  color: #839192;
}

.usagetitle {
  margin-top: -20px;
}
.buttonText {
  text-transform: unset !important;
}
.smDivclass {
  text-align: right;
  margin-top: 10px;
}

.smDivLeft {
  text-align: left;
}
.lineChart {
  height: 300px !important;
}
.smallScreenstyles {
  text-align: center;
}
.bigScreenstyles {
  text-align: left;
}
.buttonDisable {
  padding: 1vh !important;
}

@media all and (max-width: 768px) {
  .dividerVertical {
    width: 100% !important;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .floatLeft {
    margin-left: 10% !important;
  }
 
}

.titleTransform {
  text-transform: uppercase !important;
}





