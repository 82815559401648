.icon {
  display: flex;
  justify-content: center;
}

.linkText {
  display: flex;
  justify-content: center;
}
.linkTextDisable {
  display: flex;
  font-size: 15px !important;
  justify-content: center;
}

/* .dividerHorizontal {
  padding: 0.6px !important;
  width: 100%
} */
.amountDueTitle {
  font-size: 15px;
  text-align: center;
  line-height: 25px;
}
.dividerHorizontal {
  padding: 0.6px !important;
  width: 100%;
}

.MuiListItemText-root {
  flex: 0 1 auto !important;
}

.MuiListItem-root {
  justify-content: center !important;
}

.dividerVertical {
  display: flex;
  flex-direction: column;
  height: auto;
  padding: 0.5px;
  border: none;
  margin: 0px;
  background: rgba(0, 0, 0, 0.12);
  margin-right: 27px;
  align-items: center;
  justify-content: center;
}
h4 {
  color: #f14f3f;
  font-size: 20px;
  text-align: center;
}
.duemoney {
  color: #064e74;
  font-size: 2rem !important;
  font-weight: 600 !important;
  text-align: center;
}
.duetext {
  color: #064e74;
  font-size: 15px;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
}
.paynow {
  height: 39px;
  width: 155px;
  border-radius: 19.5px;
  background-color: #f14f3f;
}
.noDataFound{
  margin-top: 20px !important;
}
@media all and (max-width: 768px) {
  .dividerVertical {
    width: 100% !important;
    background: rgba(0, 0, 0, 0.12) !important;
  }
}

@media all and (max-width: 768px) {
  .MuiGrid-grid-sm-4 {
    flex-grow: 0 !important;
    max-width: 100% !important;
    flex-basis: 100% !important;
  }
}

@media all and (max-width: 768px) {
  .MuiGrid-grid-sm-8 {
    flex-grow: 0 !important;
    max-width: 100% !important;
    flex-basis: 100% !important;
  }
}

@media all and (max-width: 768px) {
  .MuiGrid-grid-sm-7 {
    flex-grow: 0 !important;
    max-width: 100% !important;
    flex-basis: 100% !important;
  }
}

@media all and (max-width: 768px) {
  .MuiGrid-grid-xs-4 {
    flex-grow: 0;
    max-width: 100% !important;
    flex-basis: 100% !important;
  }
}

.MuiListItem-root {
  padding-bottom: 0px !important;
}

.listStyling {
  width: 100%;
  padding-top: 0px;
  padding-bottom: 0px;
}

.cardContent {
  color: white;
  text-align: center;
}
.usageCard {
  width: 100%;
}
